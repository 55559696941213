<template>
  <div class="pre-order-history-container">
    <VueText style="color: #23303D; margin:25px 0 20px 20px" weightLevel="1" sizeLevel="7"
      >Ön Sipariş Geçmişi</VueText
    >
    <VueText style="color: #79838E; margin:0 0 10px 20px; " sizeLevel="5" weightLevel="1"
      >Ön sipariş numarasına göre ara</VueText
    >
    <VueTableDynamic
      id="table"
      :params="params"
      ref="tableRef"
      :customFilterMethodForColumn="customFilterMethodForColumn"
      ><template v-slot:column-3="{ props }">
        <div class="table_detail" @click="selectedOrderDetail(props.rowData)">
          Detay
        </div>
      </template></VueTableDynamic
    >
    <BrandSurveyPopup
      style="align-items: center; backdrop-filter: blur(1px) !important;"
      :show.sync="isDetailPopup"
      :hasCloseIcon="false"
    >
      <div class="popup-detail">
        <div v-for="field in fields" :key="field.label" class="field">
          {{ field.label }}:
          <div v-if="field.label != 'Durum'" class="value">
            {{ field.value }}
          </div>
          <div v-else class="value" :style="getTextColor(field)">
            {{ field.value }}
          </div>
        </div>
        <VueButton @click="isDetailPopup = !isDetailPopup" class="back">GERİ DÖN</VueButton>
      </div>
    </BrandSurveyPopup>
  </div>
</template>

<script>
import BrandSurveyPopup from '@/components/brand/Modals/BrandModal/BrandSurveyPopup.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueTableDynamic from 'vue-table-dynamic';
import { ref, onBeforeMount, nextTick } from 'vue';
import { BusinessDashboard } from '@/services/Api/index';

export default {
  components: { BrandSurveyPopup, VueTableDynamic, VueText, VueButton },
  setup() {
    const isTextAppended = ref(false);
    const isDetailPopup = ref(false);
    const selectedOrder = ref(null);
    const allOrderData = ref([]);
    const tableRef = ref(null);
    const fields = ref([]);

    const params = ref({
      data: [['Ön Sipariş Tarihi', 'Ön Sipariş Numarası', 'Teslimat Günü', '']],
      header: 'row',
      headerHeight: 60,
      rowHeight: 50,
      border: true,
      enableSearch: true,
      converting: false,
      headerBgColor: '#f8f9f9',
      fixed: 1,
      scrollbar: 'hidden',
      columnWidth: [
        { column: 0, width: 100 },
        { column: 1, width: 120 },
        { column: 2, width: 100 },
        { column: 3, width: 100 },
      ],
    });

    const desiredOrder = ['preOrderOrderDate', 'preOrderCode', 'preOrderDeliveryDate', ''];

    const getTextColor = field => {
      if (field.label === 'Durum') {
        return {
          color:
            field.value === 'İptal' ? '#e5472d' : field.value === 'Açık' ? '#6DD400' : '#000000',
        };
      }
      return { color: '#000000' };
    };

    const formatDateString = date => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date
        .getDate()
        .toString()
        .padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const customFilterMethodForColumn = (value, rowData) => {
      const preOrderCode = rowData[2];
      return preOrderCode.toLowerCase().includes(value.toLowerCase());
    };

    const appendTextToTable = async () => {
      await nextTick();

      const toolsSearchDiv = document?.querySelector('.tools-search');
      const inputElement = toolsSearchDiv?.querySelector('input');

      if (inputElement) {
        inputElement.placeholder = 'Ön Sipariş Numarası';
      }
    };

    const queryString = () => {
      const currentDate = new Date();
      const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

      const startDateFormatted = formatDateString(firstDayOfMonth);
      const endDateFormatted = formatDateString(lastDayOfMonth);
      return `startDate=${startDateFormatted}&endDate=${endDateFormatted}`;
    };

    const formatOrderDate = date => {
      if (!date) return;
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear() % 100}`;
    };

    const getDeliveryDay = date => {
      return date.toLocaleDateString('tr-TR', { weekday: 'long' });
    };

    const getStatusText = status =>
      ({
        X: 'İptal',
        O: 'Açık',
        F: 'Sonlandırılmış',
      }[status] || 'Bilinmeyen Durum');

    const transformDataItem = item => {
      const orderDate = new Date(item.preOrderOrderDate);
      const formattedOrderDate = formatOrderDate(orderDate);

      const deliveryDate = new Date(item.preOrderDeliveryDate);
      const deliveryDay = getDeliveryDay(deliveryDate);

      const transformedItem = {};
      desiredOrder?.forEach(key => {
        transformedItem[key] = item[key];
      });

      return {
        ...transformedItem,
        preOrderOrderDate: formattedOrderDate,
        preOrderDeliveryDate: deliveryDay,
      };
    };

    const preOrderHistory = () => {
      const query = queryString();
      BusinessDashboard.getPreOrderHistory(query).then(res => {
        if (!res.data.Data) return;
        allOrderData.value = res.data.Data;
        const transformedData = res.data?.Data?.map(item => {
          return Object.values(transformDataItem(item));
        });
        params.value.data.push(...transformedData);
      });
    };

    const selectedOrderDetail = rowData => {
      const order = [];

      rowData?.forEach(f => {
        order.push(f.data);
      });

      const selectedPreOrderCode = order[1];

      selectedOrder.value = allOrderData.value.find(
        order => order.preOrderCode === selectedPreOrderCode,
      );
      isDetailPopup.value = !isDetailPopup.value;
      setDetailOrder();
    };

    const setDetailOrder = () => {
      fields.value = [
        { label: 'Ön Sipariş Tarihi', value: preOrderDeliveryDate() },
        { label: 'Teslimat Günü', value: preOrderOrderDate() },
        { label: 'Ön Sipariş Numarası', value: preOrderCode() },
        { label: 'Ödeme Tipi', value: prePaymentFormDescription() },
        { label: 'Toplam', value: preOrderNetAmountDocCur() },
        { label: 'Durum', value: preOrderStatus() },
      ];
    };

    const preOrderDeliveryDate = () => {
      const orderDate = new Date(selectedOrder?.value?.preOrderDeliveryDate);
      return formatOrderDate(orderDate);
    };

    const preOrderOrderDate = () => {
      const deliveryDate = new Date(selectedOrder?.value?.preOrderOrderDate);
      return getDeliveryDay(deliveryDate);
    };

    const preOrderCode = () => {
      return selectedOrder?.value?.preOrderCode;
    };

    const prePaymentFormDescription = () => {
      return selectedOrder?.value?.prePaymentFormDescription;
    };

    const preOrderNetAmountDocCur = () => {
      return selectedOrder?.value?.preOrderNetAmountDocCur;
    };

    const preOrderStatus = () => {
      return getStatusText(selectedOrder?.value?.preOrderStatus);
    };

    onBeforeMount(() => {
      isTextAppended.value = false;
      appendTextToTable();
      preOrderHistory();
    });

    return {
      customFilterMethodForColumn,
      prePaymentFormDescription,
      preOrderNetAmountDocCur,
      preOrderDeliveryDate,
      selectedOrderDetail,
      preOrderOrderDate,
      preOrderHistory,
      isDetailPopup,
      selectedOrder,
      allOrderData,
      preOrderCode,
      preOrderStatus,
      getTextColor,
      tableRef,
      params,
      fields,
    };
  },
};
</script>
<style lang="scss">
.pre-order-history-container {
  width: 100%;
  .tools-search {
    width: 235px !important;
    margin-bottom: 20px;
    margin-left: 20px;
  }
  .v-table-row {
    color: #23303d;
  }
  .v-table-row.is-header {
    font-size: 14px;
    font-weight: 400 !important;
    color: #79838e;
  }
  .table-cell-inner {
    place-content: center;
    color: #79838e;
    font-size: 14px;
  }

  .table-cell {
    font-size: 12px;
    color: #23303d;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    text-align-last: center;
  }

  .vue-input.prefix {
    height: 40px;
    color: #79838e;
    font-size: 14px;
    font-weight: 500 !important;
    padding-left: 10px !important;
  }
  .iconfont {
    font-size: 24px;
    margin-left: 190px;
  }
}
.table_detail {
  color: #e5472d;
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
}
.popup-detail {
  width: 335px;
  height: 321px;
  border-radius: 4px;
  background: #fff;
  margin: 16px 0;
  .back {
    bottom: 0;
    width: 100%;
    height: 61px;
    color: #fff;
    position: absolute;
    justify-content: center;
    border-radius: 0px 0px 4px 4px;
    border: 1px linear-gradient(315deg, #d20051 0%, #ffa300 100%) solid;
    background: linear-gradient(315deg, #d20051 0%, #ffa300 100%);
  }
  .field {
    display: flex;
    color: #79838e;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    margin: 24px 0 0 30px;
  }
  .value {
    color: #23303d;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
  }
}
</style>
