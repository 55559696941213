<template>
  <div :class="cssClasses.modalWrapperClasses" v-if="show" @click="closeModal">
    <div :class="cssClasses.modalContentClasses">
      <a @click="closeModal" :class="[cssClasses.modalCloseClasses]">
        <VueIcon
          v-if="hasCloseIcon"
          iconName="IconCross"
          iconColor="#000"
          :width="getIcon.cross.width"
          :height="getIcon.cross.height"
        ></VueIcon>
      </a>
      <div :class="cssClasses.modalContentWrapperClasses">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { ICON_VARIABLES } from '@/constants/component.constants.js';

export default {
  name: 'BrandInfoModal',
  components: {
    VueIcon,
  },
  props: {
    show: {
      type: Boolean,
    },
    hasCloseIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  watch: {
    show: {
      immediate: true,
      handler(valu) {
        document.body.style.overflow = valu ? 'hidden' : 'unset';
      },
    },
  },
  methods: {
    closeModal(e) {
      if (
        e.target.classList.contains(this.cssClasses.modalWrapperClasses) ||
        e.currentTarget.classList.contains(this.cssClasses.modalCloseClasses)
      ) {
        this.$emit('update:show', false);
      }
    },
  },
  computed: {
    cssClasses() {
      const modalWrapperClasses = [this.$style[`modal-wrapper`]];
      const modalContentClasses = [this.$style[`modal-content`]];
      const modalContentWrapperClasses = [this.$style[`modal-content-wrapper`]];
      const modalCloseClasses = [this.$style[`modal-close`]];

      return {
        modalWrapperClasses,
        modalCloseClasses,
        modalContentClasses,
        modalContentWrapperClasses,
      };
    },
    getIcon() {
      const { apply, cross, warn } = ICON_VARIABLES;
      return { cross, warn, apply };
    },
  },
};
</script>
<style module lang="scss">
.modal {
  &-wrapper {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 40;
    top: 0;
    left: 0;
    object-fit: contain;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.7);
  }
  &-content {
    @media screen and (max-width: 320px) {
      width: 100%;
    }
    position: relative;
    max-width: 335px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 30px;
    z-index: 1;
    background-color: palette-color-level('white', '100');
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    word-break: break-word;
    &-wrapper {
      display: flex;
      padding: palette-space-level('0');
    }
  }
  &-close {
    position: absolute;
    right: 15px;
    top: 14px;
    z-index: 1;
    svg {
      width: 15px;
    }
  }
}
</style>
